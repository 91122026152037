<template>
  <div>
    <b-card no-body>
      <b-card-header class="p-0 mb-1 bg-gradient-primary text-white">
        <h5 class="mb-0 text-white" style="padding: 0.5em">{{ msg('Role') }}</h5>
      </b-card-header>
      <b-card-body>
        <role :role-id.sync="currentRoleId"></role>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <b-card-header class="p-0 mb-1 bg-gradient-primary text-white">
        <h5 class="mb-0 text-white" style="padding: 0.5em">{{ msg('Permissions') }}</h5>
      </b-card-header>
      <b-card-body>
        <b-tabs pills vertical>
          <template #tabs-end>
            <b-button class="mt-3" variant="outline-success" :disabled="currentRoleId<=0" @click="save">
              <feather-icon icon="SaveIcon"></feather-icon>
              {{msg("Save")}}
            </b-button>
          </template>
          <b-tab
              class="justify-content-start"
              title-link-class="justify-content-start"
              v-for="module in allModulesByGroup" v-bind:key="module.name"
          >
            <template #title>
              <div class="d-inline-block">
                <feather-icon v-if="module.type === 'feathericons'" :icon="module.icon"></feather-icon>
                <i v-if="module.type === 'fontawesome'" :class="{[module.icon]:true}"></i>
              </div>
              {{ module.name }}
            </template>
            <div v-if="currentRoleId > 0">
              <b-table :ref="`permission-table-${module.group}`" striped responsive v-bind:items="rolePermissions[module.group]" class="mb-0" :fields="fields">
<!--                <template #cell(permissionName)="data" >
                  {{ data.value }}
                </template>-->
                <template #cell(description)="data">
                  {{ data.value }}
                </template>
                <template #cell(all)="data">
                  <b-button
                      variant="flat-success"
                      class="btn-icon rounded-circle"
                      @click="setAllRowValueTrue(rolePermissions[module.group][data.index])"
                  >
                    <feather-icon icon="CheckIcon" />
                  </b-button>
                </template>
                <template #cell()="data">
                  <b-form-checkbox
                      v-model="rolePermissions[module.group][data.index][data.field.key]"
                  />
                </template>
              </b-table>
            </div>
            <div v-else>
              <div class="w-100" style="position: absolute;top: 50%">
                <h4 class="w-100 text-center">{{ msg('select a role') }}</h4>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import PermissionPanel from '../../components/panels/permissionPanel'
import PageTitle from '@core/components/page-title/PageTitle'
import permissionPanel from '@/components/panels/permissionPanel'
import Role from '@/views/permission/Role'

export default {
  name: 'Permissions',
  components: { Role },
  data: () => ({
    loading: false,
    error: '',
    roles: [],
    currentRoleId: 0,
    rolePermissions: {},
  }),
  watch: {
    currentRoleId() {
      this.refreshPermission()
    }
  },
  computed: {
    ...mapGetters('permissions', ['getModules', 'getPermissions']),
    fields() {
      return [/*{
        key: 'permissionName',
        label: this.msg('name'),
        sortable: true,
      },*/
        {
          key: 'description',
          label: this.msg('description'),
          sortable: true,
        },
        {
          key:'all',
          label: this.msg('all'),
        },
        {
          key: 'read',
          label: this.msg('read'),
          sortable: true,
        },
        {
          key: 'create',
          label: this.msg('create'),
          sortable: true,
        }, {
          key: 'edit',
          label: this.msg('edit'),
          sortable: true,
        }, {
          key: 'operation',
          label: this.msg('Operate'),
          sortable: true,
        }]
    },
    allPermissions() {
      return Object.values(this.getPermissions)
          .toObject('module')
    },
    allModulesMap() {
      return this.getModules.toObject('name')
    },
    allModulesByGroup() {
        console.log("all permissions: ",this.allPermissions)
        console.log("all modules: ",this.getModules)
      return this.getModules.filter(mod => mod.name === mod.group)
          .toObject('name')
    }
  },
  methods: {
    ...mapActions('permissions', { getRolePermissions: 'get',updateRolePermission:'update' }),
    refreshTable(module){
      console.log("refreshTable",module)
      this.$refs[`permission-table-${module.group}`].refresh();
    },
    refreshPermission() {
      let $this = this
      this.getRolePermissions(this.currentRoleId)
          .then(this.mergePermissions)
    },
    mergePermissions(permissions) {
      let $this = this
      let permissionsMap = permissions.map(p => ({
        ...p,
        group: $this.allModulesMap[this.getPermissions[p.permissionName].module].group
      }))
          .toObject('permissionName')
      let othersPermissions = Object.values(this.getPermissions)
          .filter(permission => permissionsMap[permission.name] == null)
          .map(permission => ({
            read: false,
            create: false,
            edit: false,
            operation: false,
            permissionName: permission.name,
            description: permission.description,
            roleid: $this.currentRoleId,
            module: permission.module,
            group: $this.allModulesMap[permission.module].group,
          }))
          .toObject('permissionName')

      this.rolePermissions = Object.values({ ...othersPermissions, ...permissionsMap })
          .groupBy('group')
    },
    setAllRowValueTrue(permission){
        let status = !(permission['read'] === true && permission['create'] === true && permission['edit'] === true &&permission['operation'] ===true  )
      permission['read'] = status;
      permission['create'] = status;
      permission['edit'] = status;
      permission['operation'] = status;
    },
    save(){
      if(this.currentRoleId <= 0 )
        return;
      let permissions = Object.values(this.rolePermissions).flatMap(p=>p);
      this.updateRolePermission({roleId:this.currentRoleId,permissions:permissions});
    }
  }
}
</script>

<style>
#permissions .md-checkbox {
  margin: 0;
}

</style>
