<template>
<div>
  <b-row no-gutters>
    <b-col cols="3" class="text-center">
      <b-avatar
          rounded="sm"
          variant="light-primary"
          size="100"
      />
    </b-col>
    <b-col cols="9">
      <div class="select-role" v-if="editing !== true">
        <b-form-group
            label-cols="4"
            label-cols-lg="2"
            label-size="lg"
            :label="msg('Name')"
            label-for="input-lg"
        >
          <v-select
              :dir="isRTL ? 'rtl' : 'ltr'"
              v-model="currentRoleId"
              v-bind:options="roles"
              class="w-100"
              label="name"
              :reduce="(role) => role.id"
              :clearable="false"
          />
        </b-form-group>
      </div>
      <div class="edit-role" v-else>
        <b-form-group
            label-cols="4"
            label-cols-lg="2"
            label-size="lg"
            :label="msg('Name')"
            label-for="input-lg"
        >
          <b-form-input
              id="input-lg"
              v-model="role.name"
          />
        </b-form-group>
      </div>
      <div class="d-flex justify-content-end">
        <b-button variant="success" v-if="editing !== true" @click="onNew">
          <feather-icon icon="PlusIcon"></feather-icon>
          {{msg('New')}}
        </b-button>
        <span class="mr-1"></span>
        <b-button variant="warning" :disabled="currentRoleId<=0" v-if="editing !== true" @click="onEdit">
          <feather-icon icon="Edit2Icon"></feather-icon>
          {{msg('Edit')}}
        </b-button>
        <b-button variant="secondary" v-if="editing === true" @click="onCancel">
          <feather-icon icon="XIcon"></feather-icon>
          {{msg('Cancel')}}
        </b-button>
        <span class="mr-1"></span>
        <b-button variant="success" v-if="editing === true" @click="save">
          <feather-icon icon="SaveIcon"></feather-icon>
          {{msg('Save')}}
        </b-button>
      </div>
    </b-col>
  </b-row>
</div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Role',
  data(){
    return {
      roles: [],
      editing: false,
      role:{},
    }
  },
  props:{
    roleId:{
      type:Number,
      required:true
    }
  },
  created() {
    this.onLoad();
  },
  computed:{
    currentRoleId:{
      get(){
        return this.roleId;
      },
      set(roleId){
        this.$emit('update:roleId',roleId)
      }
    }
  },
  methods:{
    ...mapActions('permissions', ['refreshData']),
    ...mapActions('role', ['updateRole']),
    ...mapActions('role', { getAllRoles: 'getAll',registerRole:'register'}),
    onLoad() {
      this.refreshData()
          .then(this.refreshRoles)
    },
    refreshRoles() {
      let $this = this
      this.getAllRoles()
          .then((roles) => {
            console.log('refreshRoles', roles)
            $this.roles = roles
          }, (e) => console.log('refreshRoles error', e))
    },
    onNew(){
      this.currentRoleId = 0;
      this.role = {id:0,name:""};
      this.editing = true;
    },
    onEdit(){
      this.role = {...this.roles.find(r => r.id === this.currentRoleId)}
      this.editing = true;
    },
    onCancel(){
      this.role = {};
      this.editing = false;
    },
    save(){
      let $this = this;
      if(this.role.id > 0)
        this.updateRole(this.role).then(this.onCancel).finally(this.refreshRoles)
      else
        this.registerRole(this.role).then((id)=>{
          $this.currentRoleId = id;
          $this.onCancel();
        }).finally(this.refreshRoles);
    }
  }
}
</script>

<style scoped>

</style>
