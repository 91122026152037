<template>
    <div>
        <div v-if="loaded = true">
            <b-tabs class="tabs-title" v-model="tabIndex">
                <b-tab :title="msg('Role')" active>
                    <perfect-scrollbar style="max-height: 550px">
                        <div style="padding: 20px" class="md-scrollbar">
                            <form id="businessunitForm" ref="businessunitForm" @submit.prevent="submit">
                                <div class="md-list md-dense">
                                    <md-subheader>{{msg("Role")}}</md-subheader>
                                    <div class="row">
                                        <div class="col-2 md-layout-item md-size-4">
                                            <md-list>
                                                <md-list-item>
                                                    <md-icon class="md-primary" style="padding: 0">person</md-icon>
                                                </md-list-item>
                                            </md-list>
                                        </div>
                                        <div class="row col-10">
                                            <div class="md-layout-item mr-2">
                                                <md-field :class="{ 'md-invalid': validate('name')}">
                                                    <label>{{msg("Name")}}</label>
                                                    <md-input v-on:blur="$v.$touch()" v-model="role.name"/>
                                                    <span class="md-error" v-if="role.name!= null &&!$v.role.name.required">{{msg("Name is required")}}</span>
                                                </md-field>
                                            </div>
                                            <div class="md-layout-item mr-2">
                                                <md-field>
                                                    <label>{{msg("Price")}}</label>
                                                    <md-input v-on:blur="$v.$touch()" v-model="role.price" @keypress="isNumber($event)"/>
                                                </md-field>
                                            </div>
                                            <div class="md-layout-item">
                                                <md-checkbox v-model="role.team" class="pt-3 md-primary">
                                                    <span style="color: #000;font-weight: 600">{{msg("Team")}}</span>
                                                </md-checkbox>

                                            </div>
                                        </div>
                                    </div>
                                    <md-divider></md-divider>
                                    <div class="row">
                                        <div class="col-2 md-layout-item md-size-4">
                                            <md-list>
                                                <md-list-item>
                                                    <md-icon class="md-primary" style="padding: 0">
                                                        <i class="fas fa-user-tag"></i>
                                                    </md-icon>
                                                </md-list-item>
                                            </md-list>
                                        </div>
                                        <div class="row col-10">
                                            <md-chips v-model="role.groups" :md-placeholder="msg('Groups')">
                                                <div class="md-helper-text">{{msg('Groups')}}: {{clientsGroupsStr}}
                                                </div>
                                            </md-chips>
                                        </div>
                                    </div>
                                </div>
                                <div class="error" style="color: #ff0000" v-if="error != null">
                                    <i class="fas fa-exclamation-triangle mr-3"></i> <strong>{{error}}</strong></div>
                            </form>
                        </div>
                    </perfect-scrollbar>
                </b-tab>
                <b-tab :title="msg('Columns')" @click="loadColumns">
                    <div v-if="columns.loaded">
                        <div>
                            <div v-for="(cols,col_field) in columns.role" v-bind:key="col_field">
                                <h3>{{msg(col_field)}}</h3>
                                <div class="row w-100">
                                    <div class="col-3" v-for="(col,name) in cols" v-bind:key="name">
                                        <md-switch v-model="cols[name]" class="md-primary"><span style="color: #555">{{msg(name)}}</span>
                                        </md-switch>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="columns.error != null">
                        <b-alert show variant="danger"><i class="fas fa-exclamation-triangle mr-3"></i>
                            <strong>{{error}}</strong>
                        </b-alert>
                    </div>
                    <div v-else>
                        <b-spinner label="Spinning"/>
                        {{msg('loading')}}
                    </div>
                </b-tab>
            </b-tabs>
        </div>
        <div v-else-if="this.error != null">
            <b-alert show variant="danger"><i class="fas fa-exclamation-triangle mr-3"></i> <strong>{{error}}</strong>
            </b-alert>
        </div>
        <div v-else style="min-height: 200px">
            <div style="text-align: center;height: 100%;padding: 30% 20px 20px;">
                <b-spinner label="Spinning"/>
                {{msg("loading")}}
            </div>
        </div>
    </div>
</template>

<script>
    import {required} from 'vuelidate/lib/validators'
    import minLength from "vuelidate/src/validators/minLength";
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "permissionPanel",
        props: {
            id: Number,
        },
        watch: {
            value() {
                this.onLoad();
            },
            error() {
                if (this.error != null)
                    setTimeout(() => this.error = null, 2000)
            },
            ["role.groups"]() {
                if (!Array.isArray(this.role.groups))
                    this.role.groups = [];

                if (this.role.groups.some(g => isNaN(g)))
                    this.role.groups = this.role.groups.filter(g => !isNaN(g) && parseInt(g) > 0);
            },
        },
        data: () => ({
            loaded: false,
            tabIndex: 0,
            role: {
                id: 0,
                name: "",
                team: false,
                price: 0,
                groups: [],
            },
            error: null,
            //Listeners
            listeners: {
                submit: () => {
                }
            },
            columns: {
                role: {},
                all: {},
                loaded: false,
                error: null,
            }
        }),
        created() {
            console.log("Permission panel -> created()");
            this.$root.$on('crm::permission::submit', (this.listeners.submit = () => {
                console.log("Role submitted");
                this.submit();
            }));
            this.onLoad();

        },
        beforeDestroy() {
            this.$root.$off('crm::permission::submit', this.listeners.submit)
        },
        computed: {
            ...mapGetters('translator', ['msg']),
            ...mapGetters('permissions', {clientsGroups: 'getClientsGroups'}),
            clientsGroupsStr() {
                let cg = this.clientsGroups.filter(cg => cg != null && !isNaN(cg) && cg > 0);
                return cg.toString().replace('[', '').replace(']', '');
            }
        },
        methods: {
            ...mapActions('permissions', ['register', 'reload', 'getRole']),
            ...mapActions('permissions', {_updateRole: 'updateRole'}),
            ...mapActions('ui_components', ['getAllColumns', 'getColumns', 'saveColumns']),

            onLoad() {
                let $this = this;
                this.loaded = false;
                if (this.id == null) {
                    this.resetRole();
                    this.loaded = false;
                    return
                }
                this.getRole({id: this.id}).then(r => {
                        $this.role = r
                        this.loaded = true;
                    }
                    , e => $this.error = e
                )
            },
            resetRole() {
                this.role = {
                    id: 0,
                    name: "",
                    team: false,
                    price: 0,
                    groups: [],
                }
            },
            validate(field) {
                return this.$v.role[field] != null && this.$v.role[field].$error === true;
            },
            createRole() {
                let $this = this;
                return new Promise(function (resolve, reject) {

                    if ($this.role.name == null || $this.role.name === '')
                        return reject(this.error = this.msg("Invalid new role name"));

                    $this.loading = true;



                    $this.register({...$this.role, team: ($this.role.team ? 1 : 0)}).then(() => {
                        resolve();
                    }, (e) => $this.error = e).finally(() => {
                        $this.loading = false;
                        reject();
                    });
                });
            },
            updateRole() {
                let $this = this;
                return new Promise(function (resolve, reject) {

                    $this.loading = true;

                    $this._updateRole({...$this.role, team: ($this.role.team ? 1 : 0)}).then(() => {
                        resolve();
                    }, (e) => $this.error = e).finally(() => {
                        $this.loading = false;
                        reject();
                    });

                });
            },
            submit() {
                console.log("permissionPanel -> submit()");
                this.$v.$touch();

                let $this = this;

                if (this.$v.$invalid)
                    return;

                let p = ((isNaN(this.role.id) || parseInt(this.role.id) <= 0) ? this.createRole() : this.updateRole());

                p.then(() => {
                    $this.updateCols().finally(() => {
                        $this.$emit("submitted");
                    })
                }, (e) => {
                    $this.$emit("submitted");
                })
            },
            isNumber: function (evt) {
                evt = (evt) ? evt : window.event;
                let charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            },
            loadColumns() {
                console.log("permissionPanel -> loadColumns()");
                if (this.columns.loaded === true)
                    return;
                let $this = this;
                $this.getColumns({level: 'org'}).then((orgColumns) => {

                    //Get all org permissions then map them by column_field and column_name
                    $this.columns.org = (orgColumns.length == 0 ? {} : orgColumns.reduce((obj, col) => {

                        if (obj[col.column_field] == null)
                            obj[col.column_field] = {};

                        obj[col.column_field][col['column_name']] = (col);

                        return obj;

                    }, {}));


                    // get role columns
                    $this.getColumns({level: 'role', id: $this.id}).then((roleColumns) => {
                        //Map roleColumns by column_name
                        let cols = (roleColumns.length == 0 ? {} : roleColumns.reduce((obj, col) => Object.assign(obj, {[col['column_name']]: col}, {})));

                        //Generate another map where the key is the col_field and and the value is a map by column_name where the value is [active field] true or false
                        //Default true if the
                        $this.columns.role = Object.keys($this.columns.org).reduce((obj, col_field) => {
                            obj[col_field] =
                                Object.values($this.columns.org[col_field]).reduce(
                                    (obj, col) =>
                                        Object.assign(obj, {[col['column_name']]: ($this.columns.org[col_field][col['column_name']]['active'] == true && (cols[col['column_name']] == null || cols[col['column_name']]["active"] == true))}),
                                    {});
                            return obj;
                        }, {});


                        $this.columns.loaded = true;


                    }, (e) => {
                        $this.columns.error = e;
                        $this.columns.loaded = false;
                    })
                }, (e) => {
                    $this.columns.error = e;
                    $this.columns.loaded = false;
                })
            },
            updateCols() {
                let $this = this;
                let cols = Object.keys(this.columns.role).reduce(
                    (arr, column_field) =>
                        [
                            ...arr,
                            ...Object.keys(this.columns.role[column_field]).reduce((arr, column_name) => {
                                arr.push({
                                    column_name,
                                    column_field,
                                    active: $this.columns.role[column_field][column_name],
                                    level: "role",
                                    levelId: $this.id
                                });
                                return arr;
                            }, [])
                        ],
                    []);

                return this.saveColumns({cols});
            }
        },
        validations: {
            role: {
                name: {
                    required,
                    minLength: minLength(4),
                },
            }
        }
    }
</script>

<style scoped>

</style>
